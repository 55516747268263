const {
    // SPRINGBOARD_TAG,
    // FUSION_TAG,
    // APP_STUDIO_TAG,
    // FUSION_SERVER_TAG,
    // FUSION_AI_TAG,
    FUSION_CONNECTORS_TAG,
    // NEVER_NULL_TAG,
    // MANAGED_FUSION_TAG
  } = require('./productItems');


// If `isVisible` is `true`, the _enabled_ announcement banners are visible on the docs site. If `false`, the banners are not visible. 
// If enabled, the product announcement banner overrides the default announcement banner. 
const isVisible = true;

// The default announcement banner displays throughout the site, including the homepage.
// If `showDefault` is `true`, the default announcement banner is enabled and visible on the docs site. If `false`, the default banner is disabled.
const showDefault = false;

// The value of `headLine` is displayed as the default announcement banner text. Test the value for character count overflow. 
const headLine = 'Certificate update for all Managed Fusion clients'

// The value of `bannerLink` is the hyperlink for the `headLine` value. 
const bannerLink = '/managed-fusion/5.11/f9cxzr/attention-all-managed-fusion-clients'


// If a valid product tag is used, the value determines which product documentation displays the  product-specific announcement banner. Valid product tags are found in `config/productItems.js`. 
// If `null`, the product announcement banner is disabled, and the default announcement banner is shown. 
// You can only assign a product announcement banner to one product at a time. 
const bannerProduct = FUSION_CONNECTORS_TAG

// The value of `bannerProductHeadline` is displayed as the product announcement banner text. Test the value for character count overflow. 
const bannerProductHeadline = 'Read this major announcement for the Fusion Connectors documentation'

// The value of `bannerProductLink` is the hyperlink for the `bannerProductHeadline` value. 
const bannerProductLink = '/fusion-connectors/n7n2i9/major-announcement'

module.exports = {
    isVisible,
    showDefault,
    headLine,
    bannerLink,
    bannerProduct,
    bannerProductHeadline,
    bannerProductLink
}

